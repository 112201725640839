<template>
    <div class="card-align">
        <div class="card">
            <div class="card-body">
                <div class="margin-btm meetup-header d-flex align-items-center">
                    <div class="my-auto text-icon-align">
                        <span class=" pointer algin-right">
                            <span @click="editViewDetails()" class="icon-new-risk">
                                <feather-icon class=" rotate" icon="EditIcon" size="20" />
                            </span>
                            <span @click="updateDtail()" v-if="editableValue">
                                <button class="btn pointer btn-primary btn-sm margin-left" :disabled="!this.$store.state.auth.permissions.includes('risk-update')">update</button>
                            </span>
                        </span>
                        <div class="top-space">
                            <label v-if="editableValue">Name</label>
                            <div :class="[{'bordereditable': editableValue}, 'font-weiht']"
                                :contenteditable="editableValue" id="assetname">
                                {{RiskName}}</div>
                        </div>
                        <div v-if="editableValue">
                            <label v-if="editableValue">Type</label>
                            <v-select v-model="selectedData" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                 label="title" :options="option" multiple />
                        </div>
                        <div v-if="editableValue">
                            <label v-if="editableValue">Risk treatment plan</label>
                            <div id="RiskTreatmentPlan" :class="[{'textarea-editable': editableValue}]"
                                :contenteditable="editableValue">
                                {{RiskTreatmentPlan}}
                            </div>
                        </div>
                        <div v-if="editableValue">
                            <label>Vulnerabilities</label>
                            <vue-autosuggest :suggestions="VulnerabilityRiskOption" :limit="5"
                                v-model="selectedRiskVulnerabiltyName" id="autosuggest__input"
                                :input-props="tagInputProps" @input="getSearchVulnerability" @selected="onTagSelected"
                                :get-suggestion-value="getTagSuggestionValue">
                                <template slot-scope="{ suggestion }" style="display: flex; align-items: center">
                                    <span style="{ display: 'flex', color: 'navyblue'}">{{
                                        suggestion.item
                                        }}</span>
                                </template>
                            </vue-autosuggest>
                            <div>
                                <ul class="ul-auto-suggest">
                                    <li v-for="data in DetailedselectedVulnerbilty">
                                        <span>
                                            {{data.tag_title}}
                                        </span>
                                        <feather-icon @click="closeDataVul(data.tag_id)"
                                            class="text-primary pointer rotate" icon="XIcon" size="15" />
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div v-if="editableValue">
                         <label>Threats</label>
                            <v-select class="mr-bottom" v-model="selectedThreats" placeholder="Search" label="text"
                                :options="threatRiskOptions" @search="getSearchThreats"  />
                                <!-- {{selectedThreats[0].value}} -->
                                <!-- {{selectedThreats}} -->
                        
                        </div>
                        <div v-if="editableValue">
                            <label>Onecsf control</label>
                            <vue-autosuggest 
                            :suggestions="onecsfControlOption" 
                            :limit="10" v-model="onecsfControlOptionName"
                            id="autosuggest__input" 
                            :input-props="tagInputProps"
                            @input="getControlMapping"
                            @selected="onTagControlSelected" 
                            :get-suggestion-value="getTagSuggestionValue">
                                <template slot-scope="{ suggestion }" style="display: flex; align-items: center">
                                    <span style="{ display: 'flex', color: 'navyblue'}">{{
                                        suggestion.item
                                        }}</span>
                                </template>
                            </vue-autosuggest>
                            <div>
                                <ul class="ul-auto-suggest">
                                    <li v-for="data in controlMappingData">
                                        <span>
                                            {{data.tag_title}}
                                        </span>
                                        <feather-icon @click="closeDataControl(data.tag_id)"
                                            class="text-primary pointer rotate" icon="XIcon" size="15" />
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <label v-if="editableValue">Description</label>
                        <div :class="[{'textarea-editable': editableValue}]" :contenteditable="editableValue"
                            id="assetdescription">{{riskDescription}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!editableValue" class="card width-55">
            <div class="card-body">
                <div class="">
                    <div class="mr-50">
                        <p class="card-text">
                        <h5 class="mb-75 font-weight">
                            <span class="timeline-item-point-2"></span>
                            Vulnerabilities
                        </h5>
                        <div v-for="value in DetailedselectedVulnerbilty">
                            <feather-icon class="mr-right text-primary rotate" icon="CheckCircleIcon" size="15" />
                            {{value.tag_title}}
                        </div>
                        </p>
                    </div>
                    <div class="mr-50">
                        <p class="card-text">

                        <h5 class="mb-75 font-weight">
                            <span class="timeline-item-point"></span>
                            Threats
                        </h5>
                        <div v-for="data in selectedThreats">
                            <feather-icon  class="mr-right text-primary rotate" icon="CheckCircleIcon" size="15" />
                            {{data.text}}
                        </div>
                        </p>
                    </div>

                                <div class="mr-50">
                        <p class="card-text">

                        <h5 class="mb-75 font-weight">
                            <span class="timeline-item-point"></span>
                            Onecsf control
                        </h5>
                        <div v-for="value in controlMappingData">
                            <feather-icon class="mr-right text-primary rotate" icon="CheckCircleIcon" size="15" />
                            {{value.tag_title}}
                        </div>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { BFormTextarea, BSidebar, VBToggle, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { VueAutosuggest } from "vue-autosuggest";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data: () => ({
     RiskName: '',
        searchResultRiskName: '',
        riskDescription: '',
        riskNameUpdate: '',
        vulnerabilitiesOptions: [],
        selectedVulnerabilities: [],
        ThreatsOptions: [],
        selectedThreats: [],
        allThreats: [],
        currentRiskData: {},
        editRiskId: '',
        allThreatData: [],
        allVulnerabilities: [],
        commonData: [],
        UpdateVal: [],
        updateDate: '',
        editableValue: false,
        VulnerabilityRiskOption:[],
        threatRiskOptions:[],
        onecsfControlOption:[],
        onecsfControlOptionName: '',
        selectedRiskVulnerabilty: [],
        tagInputProps: {
          class: "form-control",
          placeholder: "Search Threats..",
        },
        autoData: [],
        selectedRiskVulnerabiltyName: '',
        selectedThreatName: '',
        DetailedselectedVulnerbilty: [],
        DetailedselectedThreats: [],
        RiskTreatmentPlan: '',
        selectedData: [],
        standardType:[],
        controlMappingData: [],
        option: [
            { title: 'Security' },
            { title: 'Privacy' },
        ],
        allPrimaryControls: [],
        type: [],
        onecsf_control_id: [],
        mapData:[]
  }),
components: {
vSelect,
VueAutosuggest
},
props: {
   
},
  async  mounted() {
        this.editRiskId = this.$route.params.id
        this.allVulnerabilities = await this.getVulnerabilities()
        this.allThreatData = await this.getThreat()
        this.updateDetailWhenOpen(this.editRiskId)
    },
methods: {
    closeDataControl(id) {
        this.controlMappingData = this.controlMappingData.filter(ele => ele.tag_id != id)
    },
        closeDataThreats(id) {
        
        this.DetailedselectedThreats = this.DetailedselectedThreats.filter(ele => ele.tag_id != id)
    },
        closeDataVul(id) {
        
        this.DetailedselectedVulnerbilty = this.DetailedselectedVulnerbilty.filter(ele => ele.tag_id != id)
    },
   async editViewDetails() {
        this.editableValue = true
        this.allPrimaryControls =await this.getAllControlsData()
    },
    updateDtail() {
        const val = []
        const mappingID = []
        const data1 = this.DetailedselectedVulnerbilty
        this.type = []
        this.controlMappingData.forEach(element => {
            mappingID.push(element.tag_id)
        });
        this.selectedData.forEach(ele => {
            this.type.push(ele.title)
        });
        data1.forEach(ele => {
            val.push(ele.tag_id)
        });
        this.riskDescription = document.getElementById("assetdescription").innerText;
        this.RiskName = document.getElementById("assetname").innerText;
        this.RiskTreatmentPlan = document.getElementById("RiskTreatmentPlan").innerText;

        let data = {
            name: this.RiskName,
            description: this.riskDescription,
            vulnerability_id: val,
            threat_id: this.selectedThreats[0]?this.selectedThreats[0].value:this.selectedThreats.value,
            treatment_plan: this.RiskTreatmentPlan,
            type: this.type,  
            onecsf_control_id: mappingID
            
        };
        const options = {
            method: "PUT",
            data: data,
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/risks/${this.editRiskId}`,
        };
        this.$http(options)
            .then((res) => {
                this.editableValue = false
                this.selectedThreats = []
                this.allThreatData.forEach(ele => {
                    if (ele._id == res.data.data.threat_id) {
                        this.selectedThreats.push({ value: ele._id, text: ele.name })
                    }
                })
                if (res.data.status == "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Risk Updated !",
                icon: "EditIcon",
                variant: "success",
              },
            });
            
          }
                
            })
            .catch((err) => {
                console.log(err);
            });

    },
    getVulnerabilities() {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerabilities`,
        };
        return this.$http(options)
            .then((res) => {
                return res.data.data.data
                // resolve(res)
            })
            .catch(err => {
                reject(err)
            })

    },
 onTagSelected(val) {
      console.log("val", val);
      if (val) {
        const selectedVal = val.item;
          this.allVulnerabilities.filter(ele => {
              if (ele.name == selectedVal) {
                if(!this.DetailedselectedVulnerbilty.find((o) => o.tag_id === ele._id)){
                    this.DetailedselectedVulnerbilty.push({
                        tag_id: ele._id,
                        tag_title: ele.name
                    })
                }
                        this.selectedRiskVulnerabiltyName= "";
              }
          })

      }

    },
    onTagControlSelected(val) {
        if (val) {
            const selectedVal = val.item;
            this.allPrimaryControls.filter(ele => {
                if (ele.name == selectedVal) {
                    if(!this.controlMappingData.find((o) => o.tag_id === ele._id)){      
                        this.controlMappingData.push({
                            tag_id: ele._id,
                            tag_title: ele.name
                        })
                    }
                    this.onecsfControlOptionName = "";
                }
            })

        }

    },
    getAllControlsData() {
        
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/control/risk_primary?type[1]=${this.selectedData[1] && this.selectedData[1].title ? this.selectedData[1].title : null}&type[0]=${this.selectedData[0] && this.selectedData[0].title ? this.selectedData[0].title :null}`,
        };
       return this.$http(options)
            .then((res) => {
                
                return res.data.data

            })
            .catch((err) => {
                console.log(err);
            });
    },
    getAllControlsDataType() {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/control/risk_primary?type[1]=${this.selectedData[1] && this.selectedData[1].title ? this.selectedData[1].title : null}&type[0]=${this.selectedData[0] && this.selectedData[0].title ? this.selectedData[0].title :null}`,
        };
        this.$http(options)
            .then((res) => {
                
                this.mapData = res.data.data
                this.onecsf_control_id.forEach(ele => {
                    const data = this.mapData.filter(el => el._id == ele)
                    for (let [key, value] of Object.entries(data)) {
                        this.controlMappingData.push({
                            tag_id: value._id,
                            tag_title: value.name,
                        });
                    }
                })

            })
            .catch((err) => {
                console.log(err);
            });
    },
    getSearchVulnerability(name) {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/vulnerability/search?name=${name}`,
        };
        this.$http(options)
            .then((res) => {
                this.autoData = res.data.data
                for (let [key, value] of Object.entries(this.autoData)) {
                    let a = {
                        data: [value.name]
                    };
                    this.VulnerabilityRiskOption.push(a);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },
        getSearchThreats(name) {
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/threat/search?name=${name}`,
        };
        this.$http(options)
            .then((res) => {
                this.autoData = res.data.data
                this.threatRiskOptions  = []
                for (let [key, value] of Object.entries(this.autoData)) {
                let a = {
                    value: value._id,
                    text: value.name,
                };
                this.threatRiskOptions.push(a);
            }
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getControlMapping(name) {
        
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/control/risk_primary?type[1]=${this.selectedData[1] && this.selectedData[1].title ? this.selectedData[1].title : null}&type[0]=${this.selectedData[0] && this.selectedData[0].title ? this.selectedData[0].title :null}&name=${name}`,
        };
        this.$http(options)
            .then((res) => {
                this.autoData = res.data.data
                for (let [key, value] of Object.entries(this.autoData)) {
                    let a = {
                        data: [value.name]
                    };
                    this.onecsfControlOption.push(a);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    },
    getTagSuggestionValue(suggestion) {
        return suggestion.item.title;
    },
    getThreat() {
        
        const options = {
            method: "GET",
            headers: { "content-type": "application/json" },
            url: process.env.VUE_APP_ONECSF_API_URL + `/threats`,
        };
        return this.$http(options)
            .then((res) => {
                return res.data.data.data
                // resolve(res)
            })
            .catch(err => {
                reject(err)
            })
    },
     updateDetailWhenOpen(id) {
                const coppyThreats =[] 
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/json" },
                    url: process.env.VUE_APP_ONECSF_API_URL + `/risks/${id}`,
                };
                this.$http(options)
                    .then((res) => {
                        this.selectedThreats = []
                        this.DetailedselectedVulnerbilty = []
                        this.selectedData = []
                        this.UpdateVal = res.data.data
                        this.RiskName = res.data.data.name
                        this.riskDescription = res.data.data.description
                        this.updateDate = res.data.data.updated_at
                        this.RiskTreatmentPlan = res.data.data.treatment_plan
                        this.standardType = res.data.data.type
                        this.onecsf_control_id = res.data.data.onecsf_control_id
                        res.data.data.type.forEach(el => {
                            this.selectedData.push({title: el})
                        });
                        
                        this.allThreatData.forEach(ele=> {
                            if(res.data.data.threat_id == ele._id){
                               let a = {
                                    text: ele.name,
                                    value:ele._id
                                }
                                this.selectedThreats.push(a)
                            }
                        })
                        // console.log(this.selectedThreats)
                        
                        this.getAllControlsDataType()
                        res.data.data.vulnerability_id.forEach(element => {
                         const datavulnerability = this.allVulnerabilities.filter(el=> el._id == element)
                            for (let [key, value] of Object.entries(datavulnerability)) {
                                this.DetailedselectedVulnerbilty.push({
                                    tag_id: value._id,
                                    tag_title: value.name,
                                });
                            }
                        });
                    })
                    .catch(err => {
                        // reject(err)
                    })

        },
}

}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.bordereditable {
    border: 1px solid #d1cdcd;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    outline: none;
    // width: 400px;
    // height: 35px;
}
.margin-left {
    margin-left: 15px
}
.width-400 {
    width: 400px
}
.icon-new-risk {
    width: 30px;
    height: 30px
}
.textarea-editable {
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    border: 1px solid #d1cdcd;
    font: medium -moz-fixed;
    font: -webkit-small-control;
    height: 65px;
    overflow: auto;
    padding: 2px;
    resize: both;
    // width: 400px;
    border-radius: 5px;
    outline: none
}
  .ul-auto-suggest {
    list-style: none;
    padding: 0;
    display: inline-flex;
  }
.ul-auto-suggest li {
    padding: 5px;
    border: 1px solid #6610f2;
    border-radius: 5px;
    margin-top: 8px;
    margin-right: 8px;
}
.top-space {
    margin-top: 15px;
}
.flex-inline{
    display: inline-flex;
    margin-right: 5px;
}
.text-icon-align {
    float:right;
    width:100%
}
.algin-right {
    float: right;
    align-items: center;
}
.mr-right {
    margin-right: 5px;
}
.card-design {
    display: flex;
    justify-content: space-between;
}
// .mr-50{
//     margin-right: 100px !important;
// }
.flex {
    display: flex;
}
.padding-card {
    padding-bottom: 1.5rem;
}
.margin-btm {
    margin-bottom: 10px;
}
.font-weiht {
    font-weight: 700;
}
.timeline-item-point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: red;
    display: inline-flex;
    margin-right: 5px;
}
.timeline-item-point-2 {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: yellow;
    display: inline-flex;
    margin-right: 5px;
}
.font-weight {
    font-weight: 800 !important;
}
</style>
